body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes section-animation-left {
  from {
    opacity: 0;
    margin-left: -60px;
  }
  to {
    opacity: 1;
    margin-left: initial;
  }
}

@keyframes section-animation-right {
  from {
    opacity: 0;
    margin-right: -60px;
  }
  to {
    opacity: 1;
    margin-right: initial;
  }
}

.section {
    opacity: 0;
    -moz-view-timeline-name: --section_image;
    view-timeline-name: --section_image;
    -moz-view-timeline-axis: block;
    view-timeline-axis: block;
    -moz-animation-timeline: --section_image;
    animation-timeline: --section_image;
    -moz-animation-range: entry 25% cover 50%;
    animation-range: entry 25% cover 50%;
}

.section-left {
  -moz-animation-name: section-animation-left;
  -moz-animation-duration: 1.5s;
  -moz-animation-fill-mode: forwards;
  animation-name: section-animation-left;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

.section-right {
  -moz-animation-name: section-animation-right;
  -moz-animation-duration: 1.5s;
  -moz-animation-fill-mode: forwards;
  animation-name: section-animation-right;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}