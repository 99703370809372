:root {
  --cero-percent: 275px;
  --decrease-percent: calc(var(--cero-percent) * 0.1);
  --ten-percent: calc(var(--cero-percent) - var(--decrease-percent));
  --twenty-percent: calc(var(--cero-percent) - (var(--decrease-percent) * 2));
  --thirty-percent: calc(var(--cero-percent) - (var(--decrease-percent) * 3));
  --fourty-percent: calc(var(--cero-percent) - (var(--decrease-percent) * 4));
  --fifty-percent: calc(var(--cero-percent) - (var(--decrease-percent) * 5));
  --sixty-percent: calc(var(--cero-percent) - (var(--decrease-percent) * 6));
  --seventy-percent: calc(var(--cero-percent) - (var(--decrease-percent) * 7));
  --eighty-percent: calc(var(--cero-percent) - (var(--decrease-percent) * 8));
  --ninety-percent: calc(var(--cero-percent) - (var(--decrease-percent) * 9));
  --hundred-percent: calc(var(--cero-percent) - (var(--decrease-percent) * 10));
}

@keyframes tenPercentJump {
  from {
    height: var(--cero-percent);
  }
  to {
    height: var(--ten-percent);
  }
}

@keyframes twentyPercentJump {
  from {
    height: var(--cero-percent);
  }
  to {
    height: var(--twenty-percent);
  }
}

@keyframes thirtyPercentJump {
  from {
    height: var(--cero-percent);
  }
  to {
    height: var(--thirty-percent);
  }
}

@keyframes fourtyPercentJump {
  from {
    height: var(--cero-percent);
  }
  to {
    height: var(--fourty-percent);
  }
}

@keyframes fiftyPercentJump {
  from {
    height: var(--cero-percent);
  }
  to {
    height: var(--fifty-percent);
  }
}

@keyframes sixtyPercentJump {
  from {
    height: var(--cero-percent);
  }
  to {
    height: var(--sixty-percent);
  }
}

@keyframes seventyPercentJump {
  from {
    height: var(--cero-percent);
  }
  to {
    height: var(--seventy-percent);
  }
}

@keyframes eightyPercentJump {
  from {
    height: var(--cero-percent);
  }
  to {
    height: var(--eighty-percent);
  }
}

@keyframes ninetyPercentJump {
  from {
    height: var(--cero-percent);
  }
  to {
    height: var(--ninety-percent);
  }
}

@keyframes hundredPercentJump {
  from {
    height: var(--cero-percent);
  }
  to {
    height: var(--hundred-percent);
  }
}

.animate-10-percent-jump {
  animation: tenPercentJump 3s ease-in-out forwards;
}

.animate-20-percent-jump {
  animation: twentyPercentJump 3s ease-in-out forwards;
}

.animate-30-percent-jump {
  animation: thirtyPercentJump 3s ease-in-out forwards;
}

.animate-40-percent-jump {
  animation: fourtyPercentJump 3s ease-in-out forwards;
}

.animate-50-percent-jump {
  animation: fiftyPercentJump 3s ease-in-out forwards;
}

.animate-60-percent-jump {
  animation: sixtyPercentJump 3s ease-in-out forwards;
}

.animate-70-percent-jump {
  animation: seventyPercentJump 3s ease-in-out forwards;
}

.animate-80-percent-jump {
  animation: eightyPercentJump 3s ease-in-out forwards;
}

.animate-90-percent-jump {
  animation: ninetyPercentJump 3s ease-in-out forwards;
}

.animate-100-percent-jump {
  animation: hundredPercentJump 3s ease-in-out forwards;
}