.react-paginate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  li {
    list-style-type: none;
    margin-left: 12px;
    margin-right: 12px;
  }

  .active-link {
    font-weight: 700;
  }
}
